import type { DirectiveBinding } from 'vue';
import sanitizeHtml from 'sanitize-html';

// Directive that maps a string containing URLs so that the URLs are replaced by anchor tags. Only works on web links (not emails, phone numbers etc.).

// Source: https://github.com/component/regexps/blob/master/index.js#L3
// const regEx = /\b(?:(?:ht|f)tp(?:s?)\:\/\/|~\/|\/)?(?:\w+:\w+@)?((?:(?:[-\w\d{1-3}]+\.)+(?:com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|edu|co\.uk|ac\.uk|it|fr|tv|museum|asia|local|travel|[a-z]{2}))|((\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)(\.(\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)){3}))(?::[\d]{1,5})?(?:(?:(?:\/(?:[-\w~!$+|.,=]|%[a-f\d]{2})+)+|\/)+|\?|#)?(?:(?:\?(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)(?:&(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)*)*(?:#(?:[-\w~!$ |\/.,*:;=]|%[a-f\d]{2})*)?\b/gmi;
const regEx = /\s*(https*\S*)\s*/gmi;

export default (el: HTMLElement, binding: DirectiveBinding) => {
    if (!binding.value) {
        return;
    }
    let text = String(binding.value);

    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    text = textArea.innerHTML;

    const parsedText = text.replaceAll(regEx, url => {
        try {
            new URL(url?.trim()); // Constructor will throw an exception if the URL is invalid
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
        } catch (_) {
            return url;
        }
    });
    el.innerHTML = sanitizeHtml(parsedText, { nestingLimit: 1 });
};
